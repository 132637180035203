<template>
  <v-layout style="margin: 0px; padding: 0px; width: 100%;">
    <v-row>
      <v-col col="12" md="5">
        <v-row wrap class="mt-10">
          <v-col cols="8" offset="2">
            <v-row style="margin-top:30px;">
              <v-col cols="12" align="center">
                <router-link to="/">
                  <v-img
                    class="centerImg2"
                    :src="'img/logo.png'"
                  />
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" align="center" justify="center">
                <span class="titleStyleColor">Sign</span>
                <span class="titleStyle"> In</span><br />
                <router-link
                  :to="'/registration'"
                  style="text-decoration: none; color: black;"
                >
                  <v-icon>mdi-account-lock-outline</v-icon>
                  <span style="color:#7CBF46"> Don't have an account?</span>
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-progress-linear
                color="#7CBF46"
                v-show="loader"
                :indeterminate="true"
              ></v-progress-linear>
              <v-col cols="12">
                <v-row>
                  <v-text-field
                    v-model="login.email"
                    label="Email"
                    append-icon="mdi-email"
                    outlined
                    dense
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    name="password"
                    label="Password"
                    outlined
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show3 ? 'text' : 'password'"
                    id="password"
                    dense
                    v-model="login.password"
                    @click:append="show3 = !show3"
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-layout color="#BD2121">
                  <h5 class="text-danger" align="center">
                    {{ errorMessage }}
                  </h5>
                </v-layout>

                <v-row>
                 <v-btn
                    class="buttonStyle"
                    @click.native="signin"
                    block
                  >
                    Sign In
                  </v-btn>
                </v-row>

                <v-row>
                  <v-col cols="12" align="center">
                    <router-link
                      :to="'/reset/password-link'"
                      style="text-decoration: none; color: black;"
                    >
                      <span style="color:#7CBF46"> Forgot Password?</span>
                    </router-link>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col col="12" md="7" class="loginPadding hidden-sm-and-down">
        <v-img
          contain
          src="https://res.cloudinary.com/dxw06ktju/image/upload/v1697799645/loginBg_n1uys1.jpg"
          style="margin: 0px; padding: 0px; width: 100%;"
        >
          <div class="overlay"></div>
        </v-img>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  name: "Login",

  data: () => ({
    loader: false,
    checkbox: false,
    errorMessage: "",
    show3: false,
    login: {
      email: "",
      password: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  methods: {
    signin() {
      this.loader = true;
      this.errorMessage = "";
      this.$http
        .post(`${process.env.VUE_APP_URL}login`,this.login,{
          headers: {
            'apiKey':`${process.env.VUE_APP_KEY}`
          }
        })
        .then((response) => {
          if (response.data.success == true) {
            localStorage.setItem("userId", response.data.data.userId);
            
            console.log(response);
            if(response.data.data.isTwoFA)
            {
              this.$router.push({
              name: "pharma2faVerification",
              params: { email: response.data.data.email }
            });
            }
            else 
            {
              if (response.data.data.Role.name == "Admin") {
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                localStorage.setItem("userId", response.data.data.userId);
                this.$router.push({
                  name: "pharmaAdminDashboard",
                });
              }
              if (response.data.data.Role.name == "User") {
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                localStorage.setItem("userId", response.data.data.userId);
                this.$router.push({
                  name: "pharmaUserDashboard",
                });
              }
              if (response.data.data.Role.name == "Manufacturer") {
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                localStorage.setItem("userId", response.data.data.userId);
              
                this.$router.push({
                  name: "pharmaManufacturerDashboard",
                });
              }
              if (response.data.data.Role.name == "Distributor") {
                this.loader = false;
                this.$auth.setToken(response.data.token.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                localStorage.setItem(
                  "distributorId",
                  response.data.data.Distributor.distributorId
                );
                this.$router.push({
                  name: "pharmaDistributorDashboard",
                });
              }
              if (response.data.data.Role.name == "Retailer") {
                this.loader = false;
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                this.$router.push({
                  name: "pharmaRetailerDashboard",
                });
              }
              if (response.data.data.Role.name == "Professional") {
                this.loader = false;
                this.$auth.setToken(response.data.data.token, 240 + Date.now());
                this.$auth.setAuthenticatedUser(response.data.data);
                this.$router.push({
                  name: "pharmaProfessionalDashboard",
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.loginPadding {
  margin: 0px !important;
  padding: 0px !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.titleStyle {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
}

.titleStyleColor {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  text-align: center;
}

.centerImg2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align: center;
}

.buttonStyle {
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
}
</style>
